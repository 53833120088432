import React from 'react';
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import UserContext from './UserContext';

class ChartExample extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			publisher: this.props.publisher,
			data: []
		};
	}

	componentDidMount() {
		let userContext = this.context;
		let fetchUrl = "https://prhftrjsze.execute-api.us-west-2.amazonaws.com/prod/summary?event=" + this.props.event;
		if (this.state.publisher !== "") {
			fetchUrl = fetchUrl + "&publisher_id=" + this.state.publisher;
		}
		fetch(fetchUrl, {headers: {'Authorization': 'Bearer ' + userContext.token}})
			.then(res => res.json())
			.then((result) => {
				this.setState({
					isLoaded: true,
					data: result
				});
			},
				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
	}

	publisherTitle(publisher) {
		if (publisher === "") {
			return "Overall"
		} else if (publisher === "PID-3ec7024d") {
			return "Unstructured"
		} else if (publisher === "PID-5790223a") {
			return "Rolling Live"
		} else if (publisher === "PID-0037ccc8") {
			return "FullCast"
		}
		return "Unknown"
	}

	eventTitle(event) {
		if (event === "download") {
			return "Downloads"
		} else if (event === "pixel") {
			return "Pixel Events"
		} else if (event === "redirect") {
			return "Redirects"
		}
		return "Unknown"
	}

	heading(publisher, event) {
		return this.publisherTitle(publisher) + " " + this.eventTitle(event)
	}

	trimToDate(tickItem) {
		const parts = tickItem.split("T");
		return parts[0]
	}

	render() {
		const {error, isLoaded, data} = this.state;
		const heading = this.heading(this.props.publisher, this.props.event);
		const lineName = this.eventTitle(this.props.event);
		if (error) {
			return <div>Error: {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			return (
				<div>
				<h2>{heading}</h2>
				<ResponsiveContainer height={400} width="100%">
				<LineChart
				data={data}
				margin={{
					top: 5, right: 30, left: 20, bottom: 5,
				}}
				>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="Date" tickFormatter={this.trimToDate} />
				<YAxis />
				<Tooltip />
				<Legend />
				<Line type="monotone" name={lineName} dataKey="Count" stroke={this.props.color} />
				</LineChart>
				</ResponsiveContainer>
				</div>
			);
		}
	}
}

export default ChartExample;

