import React from 'react';
import { GoogleLogout } from 'react-google-login';
import UserContext from './UserContext';

const clientId = '1039704906325-imdl9o0b5cahcjlon2mfe037l1jokpbo.apps.googleusercontent.com';

class Logout extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.onSuccess = this.onSuccess.bind(this);
	}

	onSuccess(res) {
		let userContext = this.context;
		console.log('res:', res);
		userContext.setToken("");
	}

	render() {
		return (
			<div>
			<GoogleLogout
			clientId={clientId}
			buttonText="Logout"
			onLogoutSuccess={this.onSuccess}
			/>
			</div>
		);
	}
}

export default Logout;
