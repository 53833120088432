import React from 'react';
import ReactDOM from 'react-dom';
import Login from './Login';
import Logout from './Logout';
import ChartExample from './ChartExample';
import CampaignChart from './CampaignChart';
import FilterControls from './FilterControls';
import FilterCampaignControls from './FilterCampaignControls';
import UserContext from './UserContext';
import './index.css';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.setToken = (token) => {
			// alert("Setting token to " + token);
			this.setState(state => ({
				token: token
			}))
		};

		this.state = {
			token: "",
			setToken: this.setToken,
			publisher: "",
			campaign: "",
		};

		this.onPublisherChange = this.onPublisherChange.bind(this);
		this.onCampaignChange = this.onCampaignChange.bind(this);
	}

	onPublisherChange(publisher) {
		this.setState({publisher: publisher});
	}

	onCampaignChange(campaign) {
		this.setState({campaign: campaign});
	}

	render() {
		const {token} = this.state;
		if (token === "") {
			return (
				<UserContext.Provider value={this.state}>
				  <Login />
				</UserContext.Provider>
			);
		} else if (this.state.campaign !== "") {
			return (
				<UserContext.Provider value={this.state}>
			      <Logout />
				  <FilterCampaignControls key={"f_c_"+this.state.campaign} onCampaignChange={this.onCampaignChange} campaign={this.state.campaign} />
				  <CampaignChart key={"c_"+this.state.campaign} campaign={this.state.campaign} color="#8884d8" />
				</UserContext.Provider>
			);
		} else if (this.state.publisher !== "" ) {
			return (
				<UserContext.Provider value={this.state}>
			      <Logout />
				  <FilterControls key={"f_p_"+this.state.publisher} onPublisherChange={this.onPublisherChange} publisher={this.state.publisher} />
				  <ChartExample key={"d_"+this.state.publisher} event="download" color="#8884d8" publisher={this.state.publisher} />
				  <ChartExample key={"p_"+this.state.publisher} event="pixel" color="#ff7300" publisher={this.state.publisher} />
				  <ChartExample key={"r_"+this.state.publisher} event="redirect" color="#82ca9d" publisher={this.state.publisher} />
				</UserContext.Provider>
			);
		} else {
			return (
				<UserContext.Provider value={this.state}>
			      <Logout />
				  <FilterControls key={"f_p_"+this.state.publisher} onPublisherChange={this.onPublisherChange} publisher={this.state.publisher} />
				  <FilterCampaignControls key={"f_c_"+this.state.campaign} onCampaignChange={this.onCampaignChange} campaign={this.state.campaign} />
				  <ChartExample key={"d_"+this.state.publisher} event="download" color="#8884d8" publisher={this.state.publisher} />
				  <ChartExample key={"p_"+this.state.publisher} event="pixel" color="#ff7300" publisher={this.state.publisher} />
				  <ChartExample key={"r_"+this.state.publisher} event="redirect" color="#82ca9d" publisher={this.state.publisher} />
				</UserContext.Provider>
			);
		}
	}
}

ReactDOM.render(
	<App />,
	document.getElementById('root')
);
