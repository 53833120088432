import React from 'react';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import UserContext from './UserContext';
import BucketChart from './BucketChart';

class CampaignChart extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			campaign: this.props.campaign,
			bucket: "",
			data: []
		};
		this.handleBucketClick = this.handleBucketClick.bind(this);
	}

	componentDidMount() {
		let userContext = this.context;
		let fetchUrl = "https://prhftrjsze.execute-api.us-west-2.amazonaws.com/prod/campaign?campaign=" + this.props.campaign;
		fetch(fetchUrl, {headers: {'Authorization': 'Bearer ' + userContext.token}})
			.then(res => res.json())
			.then((result) => {
				const gData = result.map(e => ({
					Name: e.UtmSource + "," + e.UtmMedium + "," + e.UtmContent,
					Count: e.Count,
					UtmSource: e.UtmSource,
					UtmMedium: e.UtmMedium,
					UtmContent: e.UtmContent
				}));
				this.setState({
					isLoaded: true,
					data: gData
				});
			},
				(error) => {
					this.setState({
						isLoaded: true,
						error
					});
				}
			)
	}

	publisherTitle(publisher) {
		if (publisher === "") {
			return "Overall"
		} else if (publisher === "PID-3ec7024d") {
			return "Unstructured"
		} else if (publisher === "PID-5790223a") {
			return "Rolling Live"
		}
		return "Unknown"
	}

	eventTitle(event) {
		if (event === "download") {
			return "Downloads"
		} else if (event === "pixel") {
			return "Pixel Events"
		} else if (event === "redirect") {
			return "Redirects"
		}
		return "Unknown"
	}

	heading(publisher, event) {
		return this.publisherTitle(publisher) + " " + this.eventTitle(event)
	}

	trimToDate(tickItem) {
		const parts = tickItem.split("T");
		return parts[0]
	}

	handleBucketClick(data, e) {
		this.setState({bucket: data.activeTooltipIndex});
	}

	bucketDetails() {
		if (this.state.bucket !== "") {
			const bucket = this.state.data[this.state.bucket];
			return <BucketChart key={"b_"+bucket.UtmSource+bucket.UtmMedium+bucket.UtmContent} campaign={this.state.campaign} source={bucket.UtmSource} medium={bucket.UtmMedium} content={bucket.UtmContent} />
		}
		return "";
	}

	render() {
		const {error, isLoaded, data} = this.state;
		if (error) {
			return <div>Error: {error.message}</div>;
		} else if (!isLoaded) {
			return <div>Loading...</div>;
		} else {
			const bucketInfo = this.bucketDetails();
			return (
				<div>
				<h2>Campaign {this.state.campaign}</h2>
				<ResponsiveContainer height={400} width="100%">
				<BarChart
				data={data}
				onClick={this.handleBucketClick}
				margin={{
					top: 5, right: 30, left: 20, bottom: 5,
				}}
				>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="Name" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Bar dataKey="Count" fill="#8884d8" />
				</BarChart>
				</ResponsiveContainer>
				{bucketInfo}
				</div>
			);
		}
	}
}

export default CampaignChart;

