import React from 'react';

class FilterCampaignControls extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			campaign: props.campaign,
			onCampaignChange: props.onCampaignChange,
		};
		this.handleCampaignChange = this.handleCampaignChange.bind(this);
	}

	handleCampaignChange(event) {
		this.state.onCampaignChange(event.target.value);
	}

	render() {
		return (
			<form>
			  <label>Filter By Campaign:
			    <select value={this.state.campaign} onChange={this.handleCampaignChange}>
			      <option value="">No Filter</option>
			      <option value="ch1">ch1</option>
			      <option value="lm1">lm1</option>
			      <option value="sg1">sg1</option>
			      <option value="vbn">vbn</option>
			    </select>
			  </label>
			</form>
		)
	}
}

export default FilterCampaignControls;

