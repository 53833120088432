import React from 'react';

class FilterControls extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			publisher: props.publisher,
			onPublisherChange: props.onPublisherChange,
		};
		this.handlePublisherChange = this.handlePublisherChange.bind(this);
	}

	handlePublisherChange(event) {
		this.state.onPublisherChange(event.target.value);
	}

	render() {
		return (
			<form>
			  <label>Filter By Publisher:
			    <select value={this.state.publisher} onChange={this.handlePublisherChange}>
			      <option value="">No Filter</option>
			      <option value="PID-3ec7024d">Unstructured</option>
			      <option value="PID-5790223a">Rolling Live</option>
			      <option value="PID-0037ccc8">FullCast</option>
			    </select>
			  </label>
			</form>
		)
	}
}

export default FilterControls;

