import React from 'react';
import { GoogleLogin } from 'react-google-login';
import UserContext from './UserContext';

const clientId = '1039704906325-imdl9o0b5cahcjlon2mfe037l1jokpbo.apps.googleusercontent.com';

class Login extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.onSuccess = this.onSuccess.bind(this);
	}

	onSuccess(res) {
		let userContext = this.context;
		console.log('res:', res);
		userContext.setToken(res.tokenId);
	}

	onFailure(res) {
		console.log('Login failure:', res);
	}

	render() {
		return (
			<div>
			<GoogleLogin
			clientId={clientId}
			buttonText="Login"
			onSuccess={this.onSuccess}
			onFailure={this.onFailure}
			cookiePolicy={'single_host_origin'}
			isSignedIn={true}
			/>
			</div>
		);
	}
}

export default Login;
